import consumer from "./consumer"
import { get } from "@rails/request.js"

submitSearchForm = (formId) => {
  if(form = document.getElementById(formId)) {
    if(element = form.getElementsByTagName('select')[0]) {
      element.dispatchEvent(new Event('change'))
    }
  }
}

updateHeader = () => {
  if($('.right-nav .upgrade-info').length > 0) {
    $('.right-nav').children(':not(.upgrade-info)').remove()
    $('.right-nav .upgrade-info').removeClass('hidden')
  }
}

consumer.subscriptions.create("ContractChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('connected[ContractChannel]', window.location.pathname)
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log('disconnected[ContractChannel]')
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log('data', data)
    if(data['action'] == 'force_relaod') {
      location.reload()
    } else if(data['action'] == 'created'){
      submitSearchForm('vendors-search-form')
    } else if(data['action'] == 'first_contract') {
      if(window.location.pathname == '/') {
        location.reload()
      }
    } else if(data['action'] == 'limit_reached') {
      updateHeader()
    }
  }
});
